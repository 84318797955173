<template>
    <Over />
</template>



<script>


import Over from '@/components/Over.vue'

import store   from '@/store' ;



export default {

      name: 'PaginaOver',

      components: {

              Over
     },



    setup ( ) {


            function initPaginaData () {

                    //store.dispatch ( 'setMenuRubriekenKeuze', 77 );
                    store.dispatch ( 'setMenuStatus', 'close' );   

                    store.dispatch ( 'laadTekst' );   
                    store.dispatch ( 'laadVertaling' );   
   
                        //-----------------------------------//

                     let t = "Bas Lobik  : over het werk";

                     if ( store.state.vertaalLijst.length > 0 ) {

                            t =  store.getters.getVertaling ( "pagina_over_titel" );
                     }  

                     document.title = t;


                     //-----------------------------------//


            }


            return { 
                  // event, 
                   initPaginaData,
            };
       },


        beforeMount() {

                  this.initPaginaData();
        },


          mounted () {

               //  console.log( "Rubriek: mounted" );

                 this.initPaginaData();
          },


          updated () {

               this.initPaginaData();

             //  window.scrollTo(0,0);
          },

}

</script>