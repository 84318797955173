<template>

      <div  
            v-if="event.loading">
            <p>
              Laden {{ vertaal ( 'site_naam' ) }} 
            </p>

      </div>

      <div 
          v-else 
          class="content_over"
          >



            <div 
                  class="kolom2"
                  v-html="event.tekst.body [ event.taalkeuze ]"
                  >
              </div>



             <div
                    class="kolom1"
             >

                    <div class="foto_blok_over">

                           <div 
                                           v-for="(value_beeld, key_beeld) in event.tekst.beeld" 
                                           :key="key_beeld" 
                                      >

                                      <ImageItem 
                                               :source="value_beeld.img"  
                                               :bijschrift="value_beeld.tekst [ event.taalkeuze ] !== null  ? value_beeld.tekst [ event.taalkeuze ] : '   ' "  
                                      />

                              </div>

                 </div>
             </div>


  </div>

</template>



<script>

/*
               {{vertaal ( 'site_naam' )}}
*/


import ImageItem from '@/components/ImageItem.vue'

import { reactive, computed }  from 'vue';
import store from '@/store'


export default {

    name: 'TekstOver',

    // props: {

    //       //  kop: {      
    //       //      // required: true,     
    //       //       type: String,
    //       //       default: "",    
    //       // },

    //       //  doelgroep: {      
    //       //      // required: true,     
    //       //       type: String,
    //       //       default: "0",    
    //       // },

    // },

    components: {
            ImageItem
    },



    setup ( ) {

        const event = reactive ({

                tekst_id: 0,


                taalkeuze: computed (() => { 

                          return  store.getters.getTaalKeuze();
                }),



               tekst: computed (() => { 

                       let a =  store.getters.getTekst( 3 );

                       //console.log ( "tekst: "  + a );


                       if ( a == null ) return  { 'naam' : 'loading' , 'body' : 'loading' };

                       return a;
                }),


              loading: computed (() => { 

                      return ( store.getters.getTekstStatus ()  ) 
              }), 



        });





         function setTekstKeuze ( data ) 
         {
                  event.tekst_id     = data.id;
                 // event.tekst            = data.body [ store.taalKeuze ];
         }


        function vertaal ( code ) {

                return store.getters.getVertaling ( code );
        }



         return { 
                event, 
                vertaal,
        //        laadProjectTekst, 
                setTekstKeuze 
              };
     },




      mounted() {

                 store.dispatch ( 'setMenuStatus', 'close' );
           //     this.laadProjectTekst();
      },


      updated() {

             //   this.laadProjectTekst();
      },

}



</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >



.content_over 
{
        margin:  0 1rem 0 1rem;
}



.kolom1
{
      margin:  1rem 0;
      font-size: 1rem;
}



.kolom2 
{
      margin:  0 0 2rem 0;
      font-size: 1.2rem;
      line-height: 1.5em;
}




.fotoblok_over 
{
      text-align: center;
}


.kolom1 .fotoblok_over img
{
      display:  block;

      width:  5rem;
/*      width:  100%;

      max-height:  calc( 100vh - 8rem );      
      max-width:  calc( 100vw - 4rem );*/
}

.kolom1 p
{
        padding:  .5rem 0;
        text-align: left;

        font-size: .9rem;

        line-height: 1.5em;

        color: rgb( 160, 160, 160 );
}


.video
{
        aspect-ratio: 16 / 9;
        width:  100%;
}


.jaartal 
{
      display:  block;

      font-weight: 600;

      color: rgb( 255, 50, 50 );
}





/*.inspringen 
{
      display:  inline-block;
      margin-left:  6rem;
}*/


@media (min-width: 44rem) 
{
          .content_over 
          {
                  margin:  4rem 2rem 0 2rem;
                  display:  grid;

                 grid-template-columns: 20rem 1fr ;

                  grid-column-gap: 4rem;
                  grid-row-gap: 2rem;
          }


          .kolom1
          {
                margin:  0 auto;

                grid-column-start: 1;
                grid-column-end: span 1;
                grid-row-start: 1;
                grid-row-end:  span 1;

                font-size: 1rem;
          }


          .kolom2 
          {
                margin:  0 auto;

                grid-column-start: 2;
                grid-column-end: span 1;
                grid-row-start: 1;
                grid-row-end:  span 1;

                font-size: 1.2rem;
                line-height: 1.5em;
          }


          .kolom2  h1
          {
                  margin-top: 2rem;
          }


          .kolom2  h1:first-child 
          {
                  margin-top:  -.4rem;
          }


        .jaartal 
        {
              display:  inline-block;
              margin-left:  -4rem;

              width:  4rem;
              font-weight: 600;
              font-size: 1.2rem;

/*              border-top:  1px solid rgb( 220, 220, 220 );*/

        }
}





@media (min-width: 80rem) 
{
          .content_over 
          {
                  width:  63rem;
                  margin:  4rem auto 0 auto;
                  display:  grid;

                 grid-template-columns: 20rem 40rem;

                  grid-column-gap: 3rem;
                  grid-row-gap: 2rem;
          }
}




</style>
